.nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    height: 7rem;
    flex-wrap: no-wrap;
    padding: 0 2rem;
    text-transform: uppercase;
}

.nav-bar .left-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: no-wrap;
}

.nav-bar .right-nav {
    display: flex;
    align-items: center;
}

.nav-bar a {
    text-decoration: none;
    margin-right: 0.6rem;
    color: var(--text-color);
    font-weight: bold;
    font-size: 1.1rem;
}

.nav-bar a:hover {
    text-decoration: underline;
    text-decoration-thickness: 3px;
}

.nav-bar .logo-circle {
    display: flex;
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 50%;
    padding: 4px;
    background: rgb(227, 49, 9);
}

.nav-bar .logo-circle img {
    width: 100%;
    height: 100%;
    margin-top: 8px;
    background-position: center;
    background-size: cover;
}

/* Media query for nav-bar padding in mobile devices */

@media screen and (max-width: 500px) {
    .nav-bar {
        padding: 0;
        font-size: 1rem;
    }
}