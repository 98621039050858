.cards {
    display: flex;
    flex-wrap: wrap;
}

.card {
    display: flex;
    background-color: var(--bg-white);
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-basis: 45%;
    margin: 1rem 2%;
    box-shadow:
        0px 11.5px 5.3px rgba(0, 0, 0, 0.02),
        0px 33px 17.9px rgba(0, 0, 0, 0.012),
        0px 117px 80px rgba(0, 0, 0, 0.008);
    transition: all 0.3s ease-in-out;
    padding: 1rem 1.5rem;
}

.card a {
    text-decoration: none;
    color: var(--bg-color);
}

.card p {
    font-size: 1.2rem;
    color: #7e7b7b;
    margin: 1rem;
}

.card:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transform: translateY(-2px);
}

.card .btn {
    background-color: #ab3c1e;
    /* Green */
    border: none;
    color: var(--bg-color);
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    margin: 1rem 2px;
    cursor: pointer;
    box-shadow:
        2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
        6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
        12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
        22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
        41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
        100px 100px 80px rgba(0, 0, 0, 0.07);
    transition: all 0.3s ease-in-out;
}

.card .btn:hover {
    background-color: var(--text-color);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transform: translateY(-4px);
}

.card .tech {
    font-weight: bold;
    color: var(--text-color);
    margin-bottom: 0;
}

.tech-used {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-flow: row wrap;
}

.group-btn {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-flow: row wrap;
}

.language-tag {
    background-color: var(--text-dark);
    color: var(--bg-color);
    border-radius: 4px;
    padding: 0.5rem;
    margin: 0.5rem;

}

@media screen and (max-width: 500px) {
    .cards {
        flex-direction: column;
    }
}