.footer-wrap {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    padding: 0 4rem;
    margin-bottom: 2rem;
}

.footer-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: var(--bg-color);
    color: var(--text-color);
    position: relative;
    height: 4rem;
    padding: 0 1rem;
    width: 100%;
}

.footer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footer-container .social-links {
    display: flex;
    align-items: center;
    padding: 0 1rem;
    color: var(--text-color);
}

.footer-container .social-links .fab {
    font-size: 2rem;
    margin-right: 1rem;
    color: var(--text-color);
}

/* Skew */
.foot-container .fab {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;

}

.fab:hover,
.fab:focus,
.fab:active {
    -webkit-transform: skew(-10deg);
    transform: skew(-10deg);
}

.footer-container .copyright {
    font-size: 1rem;
}

.footer-container .back-to-top {
    position: absolute;
    right: 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    background: var(--text-color);
    color: var(--bg-color);
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.footer-container .back-to-top .fas {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5rem;
}

@media only screen and (max-width: 767px) {
    .footer-wrap {
        padding: 0 1rem;
    }

    .footer-container {
        padding: 0;
    }
}