.project {
    display: flex;
    flex-direction: column;
    padding: 0 4rem;
}

/* .mini-nav is styles in resume.css */

.project h2.header-project {
    font-size: 2rem;
    font-weight: bold;
    color: var(--text-color);
    margin: 2rem 0 0 0;
}

.overview {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    padding: 1rem 1rem;
    border-bottom: 1px dashed var(--text-color);
}

.overview .header {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 40%;
}

.overview .header h2 {
    font-size: 2rem;
    font-weight: bold;
    color: var(--text-color);
}

.overview p {
    font-size: 1rem;
    margin: 1rem 0 2rem 0;
    color: var(--text-color);
}

.overview h3 {
    font-size: 2rem;
    font-weight: bold;
    color: var(--text-color);
    margin: 1rem;
}

.other-projects {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 40%;
}

.other-projects h2 {
    font-size: 2rem;
    font-weight: bold;
    color: var(--text-color);
}

.overview .overview-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
}

.image-holder {
    display: flex;
    border: 6px dashed rgb(227, 49, 9);
    justify-content: center;
    align-items: center;
    padding: 1rem;
    min-width: 22rem;
    margin: 2rem auto;
    height: 20rem;
    background-size: contain;

}

.image-holder img {
    width: 100%;
    height: 100%;
    box-shadow:
        0.2px 0.3px 1.2px rgba(0, 0, 0, 0.019),
        0.3px 0.7px 2.7px rgba(0, 0, 0, 0.028),
        0.6px 1.1px 4.6px rgba(0, 0, 0, 0.034),
        0.9px 1.7px 6.9px rgba(0, 0, 0, 0.04),
        1.3px 2.4px 10px rgba(0, 0, 0, 0.044),
        1.8px 3.4px 14.2px rgba(0, 0, 0, 0.049),
        2.5px 4.8px 20.1px rgba(0, 0, 0, 0.054),
        3.7px 6.9px 29.2px rgba(0, 0, 0, 0.059),
        5.6px 10.7px 45px rgba(0, 0, 0, 0.064),
        10px 19px 80px rgba(0, 0, 0, 0.07);
}

#otherProjects.overview .header {
    display: flex;
    justify-content: center;
    margin: 0 4rem;
    width: 90vw;
}

#otherProjects.overview {
    border-bottom: none;
}

@media screen and (max-width: 500px) {
    .project {
        padding: 0 2rem;
    }

    .overview {
        flex-direction: column;
    }

    .overview .overview-details {
        width: 100%;
    }
}

@media screen and (min-width: 700px) {
    .image-holder {
        min-width: 40rem;
        height: 25rem;
    }
}