.contact {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 100%;
    padding: 2rem 4rem;
}

.contact a {
    color: var(--links-color);
}

.contact-header {
    font-size: 3rem;
}

.contact-text {
    font-size: 1rem;
    margin: 1rem 0 2rem 0;
}

@media only screen and (max-width: 767px) {
    .contact {
        padding: 2rem 2rem;
    }
}