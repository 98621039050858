.resume {
    display: flex;
    flex-direction: column;
    padding: 0 4rem;
}

.resume .hubbies p {
    font-size: 1rem;
    padding: 0 0 1rem 0;
    color: var(--text-color);
}

.resume h2.resume-header {
    font-size: 2rem;
    font-weight: bold;
    color: var(--text-color);
    margin: 2rem 0 0 0;
}

.resume ul li,
.resume .left p {
    font-size: 1rem;
    color: var(--text-color);
    margin: 0.5rem 0;
}

.resume .left ul.sub {
    padding-left: 1.5rem;
}

.mini-nav {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    height: 2rem;
    flex-wrap: nowrap;
    margin: 1rem 1rem 0 1rem;
    padding: 0;
}

.mini-nav a {
    text-decoration: underline;
    margin-right: 0.6rem;
    color: var(--text-color);
    font-size: 1rem;
    text-transform: uppercase;

}

/* Mini Nav effects */
.mini-nav>a {
    border: 1.5px solid rgba(138, 26, 26, 0.944);
    border-radius: 5px;
    background: var(--bg-white);
    text-decoration: none;
    padding: 4px;
    transition: font-weight 0.2s ease-in-out;
    animation: blink 4s linear infinite;
}

.mini-nav a:hover {
    font-weight: bold;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: no-wrap;
    padding: 1rem 0 0 0;
    border-bottom: 1px dashed var(--text-color);
}

.container .content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 95%;
    margin: 1rem;
}

.container .element {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    margin: 1rem auto;
}

.container .content h2 {
    font-size: 2rem;
    font-weight: bold;
    color: var(--text-color);
}


.other-projects h2 {
    font-size: 2rem;
    font-weight: bold;
    color: var(--text-color);
}

.element .left {
    display: flex;
    flex-direction: column;
    width: 45vw;
    margin-left: 1rem;
}

.element .right {
    display: flex;
    flex-direction: column;
    width: 35vw;
}

.element p {
    margin: 0.2rem;
}

.element h3 {
    margin-top: 0;
}

.other-projects {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 60%;
}

/* Skills Icons session */

.icons .language-tag {
    font-weight: bold;
    text-align: left;
    width: 13rem;
    padding: 0.6rem 2rem;
    margin: 1rem 1rem;
    transition: all 0.3s ease-in;
}

.hidden {
    display: none;
}

.text {
    transition: all 0.7s ease-in;
}

.icons {
    transition: all 0.7s ease-in;
}

.text h3 {
    background-color: rgb(150, 149, 149);
    font-size: 1.3rem;
    font-weight: bold;
    color: var(--text-color);
    padding: 1rem;
    margin: 1rem;
    cursor: pointer;
    box-shadow:
        0.2px 0.3px 1.2px rgba(0, 0, 0, 0.019),
        0.3px 0.7px 2.7px rgba(0, 0, 0, 0.028),
        0.6px 1.1px 4.6px rgba(0, 0, 0, 0.034),
        0.9px 1.7px 6.9px rgba(0, 0, 0, 0.04),
        1.3px 2.4px 10px rgba(0, 0, 0, 0.044),
        1.8px 3.4px 14.2px rgba(0, 0, 0, 0.049),
        2.5px 4.8px 20.1px rgba(0, 0, 0, 0.054),
        3.7px 6.9px 29.2px rgba(0, 0, 0, 0.059),
        5.6px 10.7px 45px rgba(0, 0, 0, 0.064),
        10px 19px 80px rgba(0, 0, 0, 0.07);
}

.text h3:hover,
.text h3:focus,
.text h3:active {
    background-color: var(--bg-white);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transform: translateY(-2px);
}

@keyframes blink {
    50% {
        background: var(--bg-color);
    }
}



@media only screen and (max-width: 767px) {
    .resume {
        padding: 0 1rem;
    }
}