.home {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem 6rem;
}

.hero {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

}

.hero .hero-text,
.hero .hero-image {
    flex-basis: 45%;
}

.hero .hero-text {
    display: flex;
    flex-direction: column;
    text-align: left;
    height: fit-content;
}

.hero .hero-text a {
    color: var(--links-color);
}

.featured-projects a {
    color: var(--links-color);
}

.hero .hero-text h1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--text-color);
    margin: 0 0 1rem 0;
}

.hero .hero-text p {
    font-size: 1.2rem;
    color: var(--text-color);
    margin: 0 0 2rem 0;
}

.hero .hero-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.hero .hero-image img {
    width: 80%;
    height: 100%;
    object-fit: cover;
    background-position: center;
}


.home .featured-projects {
    padding: 1rem 0;
    width: 100%;
}

@media only screen and (max-width: 767px) {
    .home {
        padding: 0 2rem;
    }

    .hero {
        flex-direction: column;
    }

    .hero .hero-text,
    .hero .hero-image {
        flex-basis: 100%;
    }

    .hero .hero-text {
        width: 100%;
    }
}